.award-img {
    height: 120px;
    margin-bottom: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    background: #eff0f3;
    object-fit: cover;
    overflow: hidden;
    border-radius: 15px;
}
.say-about {
    position: relative;
}
.say-about:before {
    width: 48%;
    height: 100%;
    top: 0;
    left: 0px;
    position: absolute;
    content: "";
    background: url("../../images/doc/doc4.jpg") no-repeat 50% 50%;
    background-size: cover; 
}

@media (max-width: 767px) {
    .say-about:before {
        width: 100%; /* Full width on mobile */
        height: 600px; /* Adjust height as needed */
        background-position: center; /* Center the image on mobile */
        background-size: cover; /* Ensure the image covers the entire area */
    }
    .say-about .col-lg-6.offset-lg-6 {
        position: relative; /* Ensure the text content is correctly positioned */
        z-index: 1; /* Make sure text is above the background image */
    }
    .say-about .row {
        margin: 0; /* Adjust margins/padding if needed */
        padding: 0;
    }
    .say-about .section-title {
        margin-bottom: 20px; /* Adjust spacing as needed */
    }
    .say-about .form-text {
        font-size: 14px; /* Adjust font size for better readability */
    }
}
.mds-text {
    font-size: 0.6em; /* Adjust the size as needed */
    vertical-align: baseline; /* Align it to the baseline of the parent element */
}